.lander {
   padding: 80px 0;
   text-align: center;
 }
 
.lander h1 {
   font-family: "Open Sans", sans-serif;
   font-weight: 600;
   color: #ccc;
 }
 
.lander p {
   color: #ccc;
   font-size: 1.2rem
 }
 
.lander div {
   padding-top: 20px;
 }
.lander div a:first-child {
   margin-right: 20px;
 }
 
.notes h4 {
   font-family: "Open Sans", sans-serif;
   font-weight: 600;
   overflow: hidden;
   line-height: 1.5;
   white-space: nowrap;
   text-overflow: ellipsis;
 }
.notes p {
   color: rgb(214, 20, 20);
 }


.tmdb_logo {
  position: fixed;
  bottom: 1px;
  color: #01d277;
  font-size: .9rem;
  }

.tmdb_img_logo {
  zoom: 0.40;
}
 
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #343a40;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.container {
  padding-right: 5px;
  padding-left: 5px;
}
a:link {
  color: white;
}
/* A link that has been visited */
a:visited {
  color: lightgray;
}
/* A link that is hovered on */
a:hover {
  color: white;
}
/* A link that is selected */
a:active {
  color: orange;
}
